var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('vue-custom-scrollbar', {
    staticClass: "scroll",
    attrs: {
      "settings": _vm.scrollSettings
    }
  }, [_c('div', [_c('div', {
    staticClass: "keyword-info"
  }, [_c('div', {
    staticClass: "attributes"
  }, [_c('div', {
    staticClass: "attributes-column"
  }, [_c('div', {
    staticClass: "attributes-table"
  }, [_c('div', {
    staticClass: "attribute"
  }, [_c('div', {
    staticClass: "attribute-name"
  }, [_vm._v("Показов:")]), _vm.keyword.shows == -1 ? _c('div', {
    staticClass: "attribute-value"
  }, [_c('span', {
    staticClass: "gray"
  }, [_vm._v(" — ")])]) : _c('div', {
    staticClass: "attribute-value"
  }, [_vm._v(" " + _vm._s(_vm.keyword.shows) + " ")])]), _c('div', {
    staticClass: "attribute"
  }, [_c('div', {
    staticClass: "attribute-name"
  }, [_vm._v("Дата проверки показов:")]), _vm.keyword.shows == -1 ? _c('div', {
    staticClass: "attribute-value"
  }, [_c('span', {
    staticClass: "gray"
  }, [_vm._v(" — ")])]) : _c('div', {
    staticClass: "attribute-value"
  }, [_vm._v(" " + _vm._s(_vm.keyword.date_shows) + " ")])])])]), _c('div', {
    staticClass: "attributes-column"
  }, [_c('div', {
    staticClass: "attributes-table"
  }, [_c('div', {
    staticClass: "attribute"
  }, [_c('div', {
    staticClass: "attribute-name"
  }, [_vm._v(" Найден впервые: ")]), _vm.keyword.shows == -1 ? _c('div', {
    staticClass: "attribute-value"
  }, [_c('span', {
    staticClass: "gray"
  }, [_vm._v(" — ")])]) : _c('div', {
    staticClass: "attribute-value"
  }, [_vm._v(" " + _vm._s(_vm.dateFormat(_vm.keyword.date)) + " ")])]), _c('div', {
    staticClass: "attribute"
  }, [_c('div', {
    staticClass: "attribute-name"
  }, [_vm._v(" Появился последний раз: ")]), _vm.keyword.shows == -1 ? _c('div', {
    staticClass: "attribute-value"
  }, [_c('span', {
    staticClass: "gray"
  }, [_vm._v(" — ")])]) : _c('div', {
    staticClass: "attribute-value"
  }, [_vm._v(" " + _vm._s(_vm.dateFormat(_vm.keyword.date2)) + " ")])])])])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };