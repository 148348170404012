import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import ReportMixin from "@/mixins/Report";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import AppTabs from "@/components/AppTabs.vue";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import KeywordProductsTable from "@/components/Keyword/ProductsTable.vue";
import KeywordBrandsTable from "@/components/Keyword/BrandsTable.vue";
import KeywordSellersTable from "@/components/Keyword/SellersTable.vue";
import QueriesSelectionTable from "@/components/Keyword/QueriesSelectionTable.vue";
import KeywordInfo from "@/components/Keyword/Info.vue";
import EntityCharts from "@/components/EntityCharts";
import { getEntityCellsConfig } from "@/utils/enums";
import ListAddItemButton from "@/components/List/AddItemButton.vue";
import CategoryPath from "@/components/Category/Path";
import SwitchInput from "@/components/SwitchInput.vue";
const tableComponents = {
  products: KeywordProductsTable,
  brands: KeywordBrandsTable,
  sellers: KeywordSellersTable,
  keywords: QueriesSelectionTable
};
import KeywordSearch from "@/components/Keyword/Search.vue";
export default {
  mixins: [ReportMixin],
  meta() {
    var _this$keyword;
    const name = (_this$keyword = this.keyword) === null || _this$keyword === void 0 ? void 0 : _this$keyword.keyword;
    return {
      title: name ? `Обзор показателей поискового запроса ${name}` : undefined
    };
  },
  data() {
    let cells = getEntityCellsConfig("keyword");
    const customCells = {
      avgPerDay: {
        title: "Среднее<br>продаж в день",
        multi: [{
          id: "avg_sold",
          unit: cells.avg_sold.unit
        }, {
          id: "avg_revenue",
          unit: cells.avg_revenue.unit
        }],
        color: cells.avg_sold.color
      }
      /* //&:
      losses: {
          title: `Упущенная выручка`,
              //color: cells.losses.color,    //!пока не используем losses для категорий
          color: "pink",
          multi: [
              {
                  id: "losses",
                      //unit: cells.losses.unit,
                  unit: "руб",
              },
          ],
      },
      */
    };
    delete cells.avg_sold;
    delete cells.avg_revenue;
    cells = {
      ...cells,
      ...customCells
    };
    return {
      keyword: null,
      tabs_tables_all: [{
        id: "products",
        title: "Товары"
      }, {
        id: "brands",
        title: "Бренды"
      }, {
        id: "sellers",
        title: "Продавцы"
      }, {
        id: "keywords",
        title: "Похожие запросы"
      }],
      tabs_tables_model: null,
      cells,
      limitKwd: {}
    };
  },
  async created() {},
  methods: {
    async loadKwdLimits() {
      this.limitKwd = await this.$store.dispatch("keyword/getKeywordLimit", {
        fake: 1 //this.$route.params.id,
      });
      //console.log("loadKwdLimits.221: this.limitKwd=", this.limitKwd);
    },
    getTableComponent(id) {
      return tableComponents[id];
    },
    getTableId() {
      return this._uid;
    },
    async loadKeyword() {
      ////console.log('loadKeyword.178: this.reportSettings=', this.reportSettings)

      if (this.$route.query.type == 'url') {
        const bzUrl = new URL(this.reportSettings.kwd);
        //console.log("KeywordInfo.240: bzUrl=", bzUrl);

        let mp = bzUrl.host.indexOf("www.ozon.ru") >= 0 ? "ozon" : "wb";
        let kwd = bzUrl.searchParams.get(mp == "wb" ? "search" : "text");

        //console.log("KeywordInfo.250: mp=", mp);
        //console.log("KeywordInfo.251: kwd=", kwd);

        kwd = decodeURI(kwd);
        kwd = kwd.replaceAll("+", " ");
        this.keyword = {
          k_id: 0,
          keyword: kwd
        };
      } else {
        this.keyword = await this.$store.dispatch("keyword/getKeyword", {
          ...this.reportSettings
        });
      }
    },
    async loadOverview() {
      await this.loadKwdLimits();

      //console.log("loadOverview.186: this.reportSettings=",this.reportSettings);

      return this.$store.dispatch("keyword/keywordOverviewAll", this.reportSettings);
    }
  },
  async mounted() {
    //await this.loadKeyword();
    //console.log("KeywordInfo.197: this.$route=", this.$route);
  },
  computed: {
    tabs_tables() {
      return this.tabs_tables_all.filter(tab => {
        if (tab.id === "XXX") {
          return true;
        }
        return true;
      });
    },
    track() {
      return {
        url: this.$route.fullPath,
        type: `${this.$route.params.mp.toUpperCase()} / Анализ поискового запроса / ${this.tabs_tables_model.title}`,
        name: this.keyword.keyword,
        date: this.reportSettings.date,
        date2: this.reportSettings.date2
      };
    },
    fileName() {
      return `${this.$route.params.mp.toUpperCase()} Поисковый запрос ${this.keyword.keyword} ${this.tabs_tables_model.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    reportSettings() {
      var _this$keyword2;
      if (!this.calendar) {
        return null;
      }

      ////console.log('reportSettings.227: this.tabs_tables_model=',this.tabs_tables_model)

      // if( !this.tabs_tables_model?.id ){
      //     return null;
      // }

      //let reportSettings;
      //if( this.tabs_tables_model?.id == 'keywords' ){ // для /Seo/QueriesSelectionTable
      // yyyy-mm-dd -> mm-dd-yyyy
      const dates = this.calendar.map(item => {
        const a = item.split("-");
        return a[1] + "-" + a[2] + "-" + a[0];
      });

      /*
          reportSettings = {
              negative_keywords: [],
              date_from: dates[0],
              date_to: dates[1],
              datasource: this.$route.params.mp,
              category_id: 0,
              sku: [],
              query: [this.keyword.keyword],
          }
          */
      //}else{

      const reportSettings = {
        //std
        mp: this.$route.params.mp,
        k_id: this.$route.params.k_id,
        date: this.calendar[0],
        date2: this.calendar[1],
        fbs: this.fbs,
        kwd: this.$route.params.kwd,
        //seo
        negative_keywords: [],
        date_from: dates[0],
        date_to: dates[1],
        datasource: this.$route.params.mp,
        category_id: 0,
        sku: [],
        query: [(_this$keyword2 = this.keyword) === null || _this$keyword2 === void 0 ? void 0 : _this$keyword2.keyword],
        ...this.$route.query
      };
      //}
      return reportSettings;
    },
    keywordKey() {
      return `${this.$route.params.k_id}:${this.$route.params.mp}`;
    },
    tableKey() {
      return `${this.keywordKey}:${this.tabs_tables_model.id}`;
    }
  },
  watch: {
    reportSettings: {
      handler(newVal, oldVal) {
        //handler(newVal) {

        ////console.log('watch.295: newVal=',newVal, ' oldVal=',oldVal)

        // loadKeyword() сработает только в самый 1й раз
        if (newVal && !oldVal) {
          ////console.log('watch.302: -> loadKeyword()')
          this.loadKeyword();
        }
        return;
      },
      immediate: true,
      deep: true
    }
    /*
    "$route.query": {
        handler() {
            this.loadKeyword();
        },
        deep: true,
        immediate: true,
    },
    */
  },
  components: {
    MainTitle,
    AppTabs,
    DataTableActions,
    KeywordProductsTable,
    EntityCharts,
    ReportPage,
    KeywordInfo,
    ListAddItemButton,
    CategoryPath,
    SwitchInput,
    KeywordSearch
  }
};